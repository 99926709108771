import * as React from "react"

import improveQualityOfLife from '../images/issues/improve-quality-of-life/improve-quality-of-life-hero.webp'
import educationEquity from '../images/issues/education-equity/education-equity-hero.webp'
import climateEnvironmentalJustice from '../images/issues/climate-environmental-justice/climate-environmental-justice-hero.webp'
import publicSafety from '../images/issues/public-safety/public-safety-hero.webp'
import planningDevelopment from '../images/issues/planning-development/planning-development-hero.webp'
import smallBusiness from '../images/issues/small-business/small-business-hero.webp'
import publicHealth from '../images/issues/public-health/public-health-hero.webp'
import transportation from '../images/issues/transportation/transportation-hero.webp'
import economicJusticeWorkersRights from '../images/issues/economic-justice-workers-rights/economic-justice-workers-rights-hero.webp'

/* This example requires Tailwind CSS v2.0+ */
const issues = [
    {
      title: 'Improve Quality of Life',
      href: '/issues/improve-quality-of-life',
      description:
        'The City of Providence is at a critical juncture in our history. To unite and move our city forward, we need bold and responsive leadership. Additionally, we need a clear vision and an unwavering commitment to turn our bold ideas into action. As someone who is deeply passionate about creating meaningful, sustainable change that benefits all of our residents, I\'ve been committed to protecting and enhancing the quality of life in Ward 1 neighborhoods and improving city services for all Providence residents.',
      imageUrl: `${improveQualityOfLife}`,
    },
    {
      title: 'Education Equity',
      href: '/issues/education-equity',
      description:
        'As a graduate of the Providence public school system, John understands the importance of local education on the future success and well-being of city residents. These difficult times have caused incredible uncertainty and presented innumerable challenges to the school systems, teachers, parents, and students alike. Everyone involved needs an equitable and protected educational system that provides sufficient support and structure pointed toward success. It is time to ensure that no policies reinforce systemic racism, socioeconomic inequality, or other issues that divide our communities and perpetuate inequity.',
      imageUrl: `${educationEquity}`,
    },
    {
      title: 'Climate and Environmental Justice',
      href: '/issues/climate-environmental-justice',
      description:
        'Imagine a revitalized city where all people have access to clean air and water, fresh, healthy food, safe homes, and accessible and attractive public sites. The city has ample affordable housing and transportation that everyone can use fairly to access well-paying jobs, schools, and community spaces. This type of transformation leads to an overwhelming sense of security and belonging as pollution, poverty, and environmental injustice become nothing more than a sorrowful tale of the past.',
      imageUrl: `${climateEnvironmentalJustice}`,
    },
    {
      title: 'Public Health',
      href: '/issues/public-health',
      description:
        'Providence offers multiple highly regarded hospitals and medical centers at the forefront of the healthcare industry and innovation. However, world-class care does not adequately extend to every individual, family, or community. Already unbalanced and struggling, the COVID-19 pandemic has exacerbated the racial and socioeconomic gaps in healthcare access.',
      imageUrl: `${publicHealth}`,
    },
    {
      title: 'Public Safety',
      href: '/issues/public-safety',
      description:
        'Every neighborhood in Providence can flourish if public safety becomes a priority across the city. Every resident deserves sufficient access to health services, youth development and educational opportunities, support for trauma, and anti-violence efforts on a community level. Public safety matters in schools, workplaces, and on the streets. Achieving these goals must involve a high degree of community trust and justice that focuses on restoring and rebuilding the communities most affected.',
      imageUrl: `${publicSafety}`,
    },
    {
      title: 'Planning & Development: Housing & Housing Affordability',
      href: '/issues/planning-development-housing-housing-affordability',
      description:
        'City government has many responsibilities to the people living in Providence but managing smart development projects with a focus on resiliency and equity tops the list. Currently, the decisions made about new development projects hide behind inaccessible and opaque discourse. A more transparent process improves accountability, prevents special interests and loopholes, and allows for a more effective and interactive discussion on true community benefits.',
      imageUrl: `${planningDevelopment}`,
    },
    {
      title: 'Transportation',
      href: '/issues/transportation',
      description:
        'Ward 1 and the City of Providence deserve to have transportation and infrastructure that is multimodal, that is safe and well-designed, and that is accessible to all. Innovative green technologies should also be utilized to make Ward 1 and the City of Providence prosper. As we plan for the future, we will take into consideration the needs of various users of infrastructure, such as pedestrians, families, children, seniors, bicyclists and those with disabilities.',
      imageUrl: `${transportation}`,
    },
    {
      title: 'Small Business',
      href: '/issues/small-business',
      description:
        'Developing a diverse economic base and a skilled workforce will make it easier for us to access quality jobs, goods, and services. We need to facilitate successful businesses, improve pathways to more jobs, higher wages, and facilitate the connection between residents and employers.',
      imageUrl: `${smallBusiness}`,
    },
    {
      title: "Economic Justice and Workers' Rights",
      href: '/issues/economic-justice-workers-rights',
      description:
        'The success of Providence and its people requires a strong commitment to economic justice that cannot falter during these difficult times affected by the COVID-19 pandemic. The current and future economy depends on protecting labor rights, workplace safety, and sufficient wages to support a healthy and happy life.',
      imageUrl: `${economicJusticeWorkersRights}`,
    }
  ]
  
  export default function IssueCards() {
    return (
      <div className="relative bg-gray-100 pb-20 px-4 sm:px-6 pt-10 lg:pb-28 lg:px-8">  
        <div className="relative max-w-7xl mx-auto">
          <div className="max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
            {issues.map((post) => (
              <div key={post.title} className="flex flex-col shadow-lg overflow-hidden">
                <div className="flex-shrink-0">
                  <a href={post.href}>
                    <img className="h-48 w-full object-cover" src={post.imageUrl} alt="" />
                  </a>
                </div>
                <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                  <div className="flex-1">
                    <div className="block mt-2">
                      <p className="text-xl font-semibold text-gray-900">{post.title}</p>
                      <p className="mt-3 text-base text-gray-500">{post.description}</p>
                    </div>
                    <a href={post.href} className="block mt-2">Learn More &raquo;</a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }
  