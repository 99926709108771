import * as React from "react"

/* This example requires Tailwind CSS v2.0+ */
export default function IssuesHeader() {
    return (
      <div className="bg-white">
        <div className="max-w-7xl mx-auto py-10 px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <p className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
              Issues
            </p>
            <p className="mt-5 mx-auto text-xl text-gray-500">
              John is rooted in community. John fights for all of us.
            </p>
          </div>
        </div>
      </div>
    )
  }